var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "data-page",
    {
      scopedSlots: _vm._u([
        {
          key: "query",
          fn: function () {
            return [
              _c("query-form", {
                attrs: { fields: _vm.queryFormFields },
                on: { query: _vm.query },
                model: {
                  value: _vm.queryForm,
                  callback: function ($$v) {
                    _vm.queryForm = $$v
                  },
                  expression: "queryForm",
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "header",
          fn: function () {
            return [
              _c("page-header", {
                attrs: { actions: _vm.pageHeaderActions },
                on: { act: _vm.handleEntityActions },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("Table", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { data: _vm.tableData, columns: _vm.tableColumns },
      }),
      _c("data-pagination", {
        attrs: { pagination: _vm.pagination },
        on: { paginate: _vm.query },
      }),
      _c("edit-form", {
        attrs: { items: _vm.formItems, rules: _vm.formRules },
        on: {
          validate: _vm.handleValidate,
          close: function ($event) {
            _vm.editingObj = null
          },
        },
        model: {
          value: _vm.editingObj,
          callback: function ($$v) {
            _vm.editingObj = $$v
          },
          expression: "editingObj",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }