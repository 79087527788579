<template lang="pug">
data-page
  template(
    #query
  )
    query-form(
      v-model="queryForm"
      :fields="queryFormFields"
      @query="query"
    )

  template(
    #header
  )
    page-header(
      :actions="pageHeaderActions"
      @act="handleEntityActions"
    )

  Table(
    :data="tableData"
    :columns="tableColumns"
    v-loading="loading"
  )

  data-pagination(
    :pagination="pagination"
    @paginate="query"
  )


  edit-form(
    v-model="editingObj"
    :items="formItems"
    :rules="formRules"
    @validate="handleValidate"
    @close="editingObj = null"
  )

</template>

<script>
import { save, destroy, query } from "../../../api/healthy/tCheckOrg";
import { getDictAsOptions } from "@/api";

import Uploader from "./components/Uploader.vue";

export default {
  components: {
    Uploader,
  },
  data() {
    return {
      loading: false,
      editingObj: null,
      tableData: [],
      queryForm: {},
      pagination: {
        total: 0,
        pageNumber: 1,
        pageSize: 10,
      },
      demo: "",
      demoOptions: [
        {
          label: "测试",
          value: 1,
        },
        {
          label: "测试2",
          value: 2,
        },
      ],
    };
  },
  computed: {
    queryFormFields() {
      return [
        {
          label: "机构名称",
          key: "name",
        },
        {
          label: "机构位置",
          key: "address",
        },
      ];
    },
    pageHeaderActions() {
      return [
        {
          type: "primary",
          label: "新增",
          event: "add",
          icon: "md-add",
        },
      ];
    },
    tableColumns() {
      return [
        {
          title: "机构名称",
          key: "name",
        },
        {
          title: "机构位置",
          key: "address",
        },
        {
          title: "机构图片",
          key: "img",
          render: (h, { row }) =>
            h(Uploader, {
              props: {
                value: row.img,
                viewOnly: true,
              },
            }),
        },
        {
          title: "营业时间",
          key: "name",
        },
        {
          title: "联系方式",
          key: "phone",
        },
        {
          title: "机构简介",
          key: "introduction",
        },
        {
          title: "到院须知",
          key: "name",
          tooltip: true,
        },
        {
          title: "操作",
          render: (h, { row }) =>
            h("DataRowActions", {
              props: {
                actions: this.rowActions,
                model: row,
              },
              on: {
                act: this.handleEntityActions,
              },
            }),
        },
      ];
    },
    rowActions() {
      return [
        {
          type: "primary",
          event: "edit",
          icon: "ios-create-outline",
          label: "修改",
        },
        {
          event: "delete",
          icon: "ios-create-outline",
          label: "删除",
        },
        {
          type: "danger",
          event: "view",
          icon: "ios-create-outline",
          label: "查看",
        },
      ];
    },
    formItems() {
      if (!this.editingObj) return [];

      return [
        {
          span: 24,
          label: "机构名称",
          key: "name",
        },
        {
          span: 24,
          label: "机构位置",
          key: "address",
        },
        {
          span: 24,
          label: "体检类别",
          key: "type",
          as: "check",
          mulitple: true,
          loadMethod: () => getDictAsOptions("checkType"),
        },
        {
          span: 24,
          label: "机构图片",
          key: "img",
          render: (h) =>
            h(Uploader, {
              props: {
                value: this.editingObj.img,
              },
              on: {
                input: (val) => (this.editingObj.img = val),
              },
            }),
        },
        {
          span: 24,
          label: "营业时间",
          key: "businessHours",
        },
        {
          span: 24,
          label: "联系方式",
          key: "phone",
        },
        {
          span: 24,
          label: "机构简介",
          key: "introduction",
          type: "textarea",
        },
        {
          span: 24,
          label: "到院须知",
          key: "notice",
          type: "textarea",
        },
      ];
    },
    formRules() {
      return {
        name: [
          {
            required: true,
            message: "请输入机构名称",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入机构地址",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择体检类别",
            trigger: "change",
          },
        ],
      };
    },
  },
  created() {
    this.query();
  },
  methods: {
    query(q) {
      if (this.loading) return;
      this.loading = true;
      const queryParams = Object.assign(
        {},
        this.queryFormProps,
        this.pagination,
        q
      );

      query(queryParams)
        .then((res) => {
          if (res?.success && res?.data) {
            const {
              pages: pageNumber,
              size: pageSize,
              total,
              records: tableData,
            } = res.data;

            Object.assign(this, {
              pagination: {
                total,
                pageSize,
                pageNumber,
              },
              tableData,
            });

            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleEntityActions(action, model) {
      switch (action.event) {
        case "add":
          this.editingObj = {};
          break;
        case "edit":
          this.editingObj = Object.assign({}, model);
          break;
        case "delete":
          this.handleDelete(model.id);
          break;
      }
    },

    handleDelete(ids) {
      let loading = true;
      this.$Modal.confirm({
        title: "确认删除",
        content: "您确认要删除当前数据！！！",
        loading,
        onOk: async () => {
          destroy(ids)
            .then((res) => {
              if (res?.success) {
                this.$Modal.remove();
                this.$Message.success("删除成功");
                this.query({
                  pageNumber: 1,
                });
              } else {
                this.$Message.error(res.message);
              }
            })
            .catch((err) => {
              this.$Message.error(err.message);
            })
            .finally(() => {
              loading = false;
            });
        },
      });
    },

    async handleValidate(form) {
      try {
        const res = await save(form);

        if (res?.success) {
          this.editingObj = null;
          this.$Message.success("保存成功");
          this.query({
            pageNumber: 1,
          });
        } else {
          this.$Message.error(res.message);
        }
      } catch (err) {
        this.$Message.error(err.message);
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
